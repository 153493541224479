// gamePhaseConfig

const gamePhaseConfig = {
    STARTING_PHASE : 'STARTING_PHASE',
    SIMULTANEOUS_SELECT : 'SIMULTANEOUS_SELECT',
    SIMULTANEOUS_SELECT_UPPER : 'SIMULTANEOUS_SELECT_UPPER',
    SIMULTANEOUS_SELECT_LOWER : 'SIMULTANEOUS_SELECT_LOWER',
    PASS_TO_TURN_BASED : 'PASS_TO_TURN_BASED',
    TURN_BASED_SELECT : 'TURN_BASED_SELECT',
    TURN_BASED_SOWING : 'TURN_BASED_SOWING'
}

export default gamePhaseConfig;